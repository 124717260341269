import {
  type Query,
  withToken,
  type QueryFields,
  type QueryItem,
  type QueryDeep,
} from '@directus/sdk'
import type { Collections, Schema } from '~/.directus/generated/client'
import { STATUS } from '~/types'

// Data fetching within the Pages collection for the current route
export const usePageData = (slug: string) => {
  const { $directus, $readItems } = useNuxtApp()
  const { localeProperties } = useI18n()
  const langIso = localeProperties.value.iso || ''

  return useAsyncData(
    `${langIso}/Pages/${slug}`,
    () =>
      $directus.request(
        $readItems<Schema, 'Pages', Query<Schema, Collections.Pages>>('Pages', {
          fields,
          limit: 1,
          // Filter pages
          filter: {
            status: { _eq: STATUS.PUBLISHED },
            translations: {
              languages_code: {
                _eq: langIso,
              },
              slug: {
                _eq: slug,
              },
            },
          },
          deep: deep(langIso),
        })
      ),
    {
      // Store current translation and flatten single-element array
      transform: (pages) =>
        pages.map((page) => ({
          ...page,
          currentTranslation: page.translations?.find(
            ({ languages_code: langCode }) => langCode === langIso
          ),
          currentSeo: (page?.SEO as Collections.SEO)?.translations.find(
            ({ languages_code }) => languages_code === langIso
          ),
        }))?.[0] ?? null,
    }
  )
}

// Data fetching within the Pages collection for a specific page ID
export const usePageDataById = async (
  id: string,
  version: string,
  forcedLangIso?: string,
  preview?: boolean,
  token?: string
) => {
  const { $directus, $readItem } = useNuxtApp()
  const { localeProperties } = useI18n()
  const langIso = forcedLangIso || localeProperties.value.iso || ''
  return useAsyncData(
    `${langIso}/Pages/id/${id}/${version}`,
    () =>
      $directus
        .request(
          withToken(
            token as string,
            $readItem<Schema, 'Pages', QueryItem<Schema, Collections.Pages>>(
              'Pages',
              id,
              {
                fields,
                filter: {
                  translations: {
                    languages_code: {
                      _eq: langIso,
                    },
                  },
                },
                deep: deep(langIso, preview),
                version: preview && token ? version : '',
              }
            )
          )
        )
        .catch((err) => {
          throw createError({
            statusCode: err.response.status,
            statusMessage: err.response.statusText,
            fatal: true,
          })
        }),
    {
      // Store current translation
      transform: (page) => ({
        ...page,
        currentTranslation: page?.translations?.find(
          ({ languages_code: langCode }) => langCode === langIso
        ),
        currentSeo: (page?.SEO as Collections.SEO)?.translations.find(
          ({ languages_code }) => languages_code === langIso
        ),
      }),
    }
  )
}

const componentFields: QueryFields<Schema, Collections.Components[]> = [
  'status',
  'id',
  'name',
  {
    translations: [
      'id',
      'type',
      'status',
      'template',
      'title',
      'title_tab',
      'content',
      'languages_code',
      'background_color',
      {
        background_media: ['id', 'directus_files_id'],
        includes: [
          {
            item: {
              CTAs: [
                'id',
                'type',
                {
                  translations: [
                    'id',
                    'languages_code',
                    'text',
                    'title',
                    'url',
                  ],
                  internal_cta: [
                    'collection',
                    {
                      item: {
                        Pages: [
                          'id',
                          'title',
                          {
                            translations: ['slug', 'title'],
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      {
        media: ['id', 'directus_files_id'],
      },
    ],
  },
]

const fields: QueryFields<Schema, Collections.Pages> = [
  'id',
  'title',
  'status',
  'sort',
  {
    SEO: [{ translations: ['title', 'description', 'languages_code'] }],
  },
  {
    Builder: [
      'id',
      'collection',
      {
        // 'item:Components': componentFields,
        item: {
          Components: componentFields,
          Blocks: [
            'id',
            'status',
            'name',
            'type',
            {
              Components: [
                'id',
                'collection',
                {
                  item: {
                    Components: componentFields,
                  },
                },
              ],
            },
          ],
        },
      },
    ],
  },
  {
    translations: ['id', 'title', 'slug', 'languages_code'],
  },
]
const deep: (
  langIso: string,
  preview?: boolean
) => QueryDeep<Schema, Collections.Pages> = (langIso, preview) => ({
  Builder: {
    _sort: ['sort'],
    // Filter components & blocks
    _filter: {
      _or: [
        {
          'item:Components': {
            _and: [
              ...(preview
                ? []
                : [
                    {
                      status: { _eq: STATUS.PUBLISHED },
                    },
                  ]),
              {
                translations: {
                  status: { _eq: 'shown' },
                },
              },
              {
                translations: {
                  languages_code: {
                    _eq: langIso,
                  },
                },
              },
            ],
          },
        },
        {
          'item:Blocks': {
            _and: [
              ...(preview
                ? []
                : [
                    {
                      status: { _eq: STATUS.PUBLISHED },
                    },
                  ]),
              {
                translations: {
                  languages_code: {
                    _eq: langIso,
                  },
                },
              },
            ],
          },
        },
      ] as any, // UnionTypes are not present in generated types
    },
    // Filter component/block translations
    'item:Components': {
      translations: {
        _filter: {
          languages_code: {
            _eq: langIso,
          },
        },
        includes: {
          'item:CTAs': {
            translations: {
              _filter: {
                languages_code: {
                  _eq: langIso,
                },
              },
            },
            internal_cta: {
              'item:Pages': {
                translations: {
                  _filter: {
                    languages_code: {
                      _eq: langIso,
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
    'item:Blocks': {
      Components: {
        'item:Components': {
          translations: {
            _filter: {
              languages_code: {
                _eq: langIso,
              },
            },
          },
        },
      },
    },
  },
})
